// extracted by mini-css-extract-plugin
export var disabled = "Button-module--disabled--9c987";
export var hoverable = "Button-module--hoverable--69e49";
export var root = "Button-module--root--c1f31";
export var size_lg = "Button-module--size_lg--00ea4";
export var size_md = "Button-module--size_md--9c102";
export var size_sm = "Button-module--size_sm--63d55";
export var theme_current = "Button-module--theme_current--e4b44";
export var theme_current_text = "Button-module--theme_current_text--4627d";
export var theme_primary = "Button-module--theme_primary--42880";
export var variant_solid = "Button-module--variant_solid--d7dbc";
export var variant_translucent = "Button-module--variant_translucent--d26fb";