// extracted by mini-css-extract-plugin
export var attachments = "EntityCard-module--attachments--a61bd";
export var author = "EntityCard-module--author--9938d";
export var bgImage = "EntityCard-module--bgImage--b2a1a";
export var clickable = "EntityCard-module--clickable--ed2ad";
export var cover = "EntityCard-module--cover--d9738";
export var date = "EntityCard-module--date--6d64f";
export var dateDivider = "EntityCard-module--dateDivider--458b4";
export var dateWrapper = "EntityCard-module--dateWrapper--c944b";
export var description = "EntityCard-module--description--1e751";
export var details = "EntityCard-module--details--b4bec";
export var disabled = "EntityCard-module--disabled--a464d";
export var eventDateWrapper = "EntityCard-module--eventDateWrapper--0ceb4";
export var eventText = "EntityCard-module--eventText--28f70";
export var externalLink = "EntityCard-module--externalLink--836d4";
export var facts = "EntityCard-module--facts--f77ee";
export var factsWrapper = "EntityCard-module--factsWrapper--1c34c";
export var hasVideo = "EntityCard-module--hasVideo--a0c44";
export var header = "EntityCard-module--header--5a771";
export var image = "EntityCard-module--image--4cfbd";
export var imagePlaceholder = "EntityCard-module--imagePlaceholder--2bb29";
export var imagePlaceholderEvent = "EntityCard-module--imagePlaceholderEvent--885f7";
export var link = "EntityCard-module--link--6172d";
export var logo = "EntityCard-module--logo--710fe";
export var root = "EntityCard-module--root--30fe0";
export var size_lg = "EntityCard-module--size_lg--3bde6";
export var size_lg_condenced = "EntityCard-module--size_lg_condenced--29e32";
export var size_md = "EntityCard-module--size_md--89ee6";
export var size_sm = "EntityCard-module--size_sm--940ea";
export var staticCoverImage = "EntityCard-module--staticCoverImage--60723";
export var text = "EntityCard-module--text--25b61";
export var title = "EntityCard-module--title--5bd34";
export var videoCover = "EntityCard-module--videoCover--42bbe";
export var whiteBg = "EntityCard-module--whiteBg--ebc47";
export var withAttachments = "EntityCard-module--withAttachments--150dc";
export var withBg = "EntityCard-module--withBg--24282";