import * as React from 'react';

import { Link } from '../../Link/Link';

interface Props {
  children?: React.ReactNode;
  href?: string;
}

export const MarkdownLink = ({ children, href, ...restProps }: Props) => (
  <Link to={href} target="_blank" {...restProps}>
    {children}
  </Link>
);
