import * as React from 'react';
import cx from 'classnames';

import { EntityCard, type EntityProps } from '../EntityCard/EntityCard';
import { TagList } from '../TagList/TagList';
import { Icon } from '../Icon/Icon';
import { routes } from '../../config/routes';
import { getEventTags } from '../../utils/event';
import { capitalizeFirstLetter, toSentence } from '../../utils/string';
import { renderDateInterval } from '../../utils/date';

import * as cs from './EventCard.module.css';

interface Props extends EntityProps {
  categories?: CustomTypes.FilterTag[];
  services?: CustomTypes.FilterTag[];
  skills?: CustomTypes.FilterTag[];
  withEntityType?: boolean;
  type?: string;
  authors?: readonly Queries.authorFieldsFragment[];
  slides?: string;
  audio?: string[];
  video?: string;
  live?: string;
  dateStart: string;
  dateStartISO: string;
  dateStartMonth: string;
  dateStartDay: string;
  dateEndMonth?: string;
  dateEndDay?: string;
  upcomingEvents?: boolean;
  coverImage?: Queries.BlogPostPageEntryFragment['coverImage'];
  bgImage?: Queries.BlogPostPageEntryFragment['bgImage'];
  bgColorOverflow?: boolean | null;
  randomColorIndex?: number;
  event?: string;
  city?: string;
  details?: React.ReactNode;
}

export const EventCard = ({
  slug,
  categories = [],
  services = [],
  skills = [],
  withEntityType,
  authors = [],
  type = 'talk',
  slides,
  audio,
  video,
  live,
  dateStart,
  dateStartISO,
  dateStartMonth,
  dateStartDay,
  dateEndMonth,
  dateEndDay,
  upcomingEvents,
  size,
  ...restProps
}: Props) => {
  const eventType = type === 'podcast' ? capitalizeFirstLetter(type) : '';

  const eventAuthor =
    authors && authors.length > 0
      ? `${eventType}${type === 'talk' ? 'By' : ' with'} ${toSentence(
          authors.map((a) => a.name),
          false,
        )}`
      : eventType;

  const hasDateEnd = dateEndDay && dateEndDay !== dateStartDay;
  const iconSize = size === 'sm' ? 14 : 24;
  const withImage = Boolean(restProps.coverImage || restProps.bgImage);

  return (
    <EntityCard
      type="event"
      slug={slug}
      url={routes.event(slug)}
      details={
        <TagList
          entries={getEventTags({
            categories,
            services,
            skills,
            withEntityType,
            type,
          })}
          mainVariant={withEntityType ? 'EntityType' : 'Category'}
        />
      }
      author={eventAuthor}
      attachments={
        <>
          {upcomingEvents ? (
            <DatesSmall
              dateStartDay={dateStartDay}
              dateEndDay={dateEndDay}
              dateStartMonth={dateStartMonth}
              dateEndMonth={dateEndMonth}
            />
          ) : (
            <>
              {Boolean(live || video) && <Icon name="video" size={iconSize} />}
              {Boolean(audio && audio.length > 0) && (
                <Icon name="audio" size={iconSize} />
              )}
              {Boolean(slides) && <Icon name="slides" size={iconSize} />}
            </>
          )}
        </>
      }
      date={renderDateInterval({
        dateStart,
        dateStartDay,
        dateStartMonth,
        dateEndDay,
        dateEndMonth,
        slice: true,
      })}
      dateISO={dateStartISO}
      coverContent={
        upcomingEvents && !withImage && dateStartMonth && dateStartDay ? (
          <>
            <div
              className={cx(cs.upcomingDate, {
                [cs.dateInterval]: hasDateEnd,
              })}
            >
              <div className={cs.upcomingDateWrapper}>
                <div className={cs.upcomingDateDay}>
                  {dateStartDay}
                  {hasDateEnd && '-'}
                </div>
                {(!dateEndMonth || dateStartMonth !== dateEndMonth) && (
                  <div
                    className={cx(
                      cs.text,
                      cs.upcomingDateMonth,
                      cs.upcomingDateStartMonth,
                    )}
                  >
                    {dateStartMonth}
                  </div>
                )}
              </div>

              {hasDateEnd && (
                <div className={cs.upcomingDateWrapper}>
                  <div className={cs.upcomingDateDay}>{dateEndDay}</div>
                  <div className={cx(cs.text, cs.upcomingDateMonth)}>
                    {dateStartMonth !== dateEndMonth && (
                      <span className={cs.upcomingDateStartMonthMobile}>
                        {dateStartMonth}
                        {' – '}
                      </span>
                    )}
                    {dateEndMonth}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : null
      }
      upcomingEvents={upcomingEvents}
      className={cx(cs[`size_${size}`], { [cs.withImage]: withImage })}
      size={size}
      {...restProps}
    />
  );
};

function DatesSmall({
  dateStartMonth,
  dateStartDay,
  dateEndMonth,
  dateEndDay,
}: {
  dateStartMonth: string;
  dateStartDay: string;
  dateEndMonth?: string;
  dateEndDay?: string;
}) {
  const hasDateEnd = dateEndDay && dateEndDay !== dateStartDay;

  dateStartMonth = dateStartMonth.slice(0, 3);
  dateEndMonth = dateEndMonth?.slice(0, 3);

  return (
    <div
      className={cx(cs.upcomingDateSmall, {
        [cs.dateInterval]: hasDateEnd,
      })}
    >
      <div className={cs.upcomingDateWrapper}>
        <div className={cs.upcomingDateDaySmall}>
          {dateStartDay}
          {hasDateEnd && '-'}
        </div>
        {
          <div className={cx(cs.upcomingDateMonthSmall)}>
            {dateStartMonth}
            {hasDateEnd &&
              dateStartMonth !== dateEndMonth &&
              `-${dateEndMonth}`}
          </div>
        }
      </div>

      {hasDateEnd && (
        <div className={cs.upcomingDateWrapper}>
          <div className={cs.upcomingDateDaySmall}>{dateEndDay}</div>
        </div>
      )}
    </div>
  );
}
