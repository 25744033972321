import * as React from 'react';
import {
  GatsbyImage,
  getImage,
  type IGatsbyImageData,
} from 'gatsby-plugin-image';
import cx from 'classnames';

import * as styles from './MarkdownImage.module.css';

interface Props {
  alt?: string;
  className?: string;
  gatsbyImageData?: IGatsbyImageData;
  src?: string;
  height?: number;
  width?: number;
}

export const MarkdownImage = ({
  className,
  alt = '',
  src,
  gatsbyImageData,
  height,
  width,
}: Props) => {
  if (src) {
    return (
      <div className={cx(styles.root, className)}>
        <img
          className={cx(styles.wrapper, styles.image)}
          alt={alt}
          src={src}
          height={height}
          width={width}
          loading="lazy"
        />
      </div>
    );
  }

  const image = gatsbyImageData ? getImage(gatsbyImageData) : null;

  return (
    <div className={cx(styles.root, className)}>
      {image && (
        <GatsbyImage
          alt={alt}
          className={styles.wrapper}
          image={image}
          objectFit="contain"
        />
      )}
    </div>
  );
};
