import * as React from 'react';

import * as cs from './VisuallyHidden.module.css';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  as?: string;
}

export const VisuallyHidden = ({
  as: Component = 'span',
  children,
  role,
  ...restProps
}: Props) => (
  // @ts-expect-error
  <Component {...restProps} role={role} className={cs.root}>
    {children}
  </Component>
);
