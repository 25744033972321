enum RoutesBasicEnum {
  'home',
  'services',
  'clients',
  'products',
  'opensource',
  'blog',
  'events',
  'privacy',
  'cookies',
  'noticeAtCollection',
  'blogFeed',
  'eventsFeed',
  'rssFeed',
}

type RoutesBasicValues = keyof typeof RoutesBasicEnum;

const routesBasic: {
  [key in RoutesBasicValues]: string;
} = {
  home: '/',
  services: '/services',
  clients: '/clients',
  products: '/products',
  opensource: '/opensource',
  blog: '/chronicles',
  events: '/events',
  privacy: '/privacy',
  cookies: '/cookies',
  noticeAtCollection: '/privacy#notice_at_collection',
  blogFeed: '/chronicles.atom',
  eventsFeed: '/events.atom',
  rssFeed: '/rss2email',
};

const slugRoute = (basicRoute: RoutesBasicValues) => (slug: string) =>
  `${routesBasic[basicRoute]}/${slug}`;

const engSiteRoute = (route: string) => `https://evilmartians.com${route}`;

const routesSlug = {
  service: slugRoute('services'),
  client: slugRoute('clients'),
  product: (slug: string) => `${engSiteRoute(routesBasic.products)}/${slug}`,
  opensourceProject: slugRoute('opensource'),
  blogPost: slugRoute('blog'),
  event: slugRoute('events'),
};

const routesAnchors = {
  contactUs: '#contact-us',
};

export const routes = {
  ...routesBasic,
  ...routesSlug,
  ...routesAnchors,
};

export const externalRoutes = {
  products: engSiteRoute(routesBasic.products),
  opensource: engSiteRoute(routesBasic.opensource),
  events: engSiteRoute(routesBasic.events),
};

export const navigation = [
  { label: 'サービス内容', url: routesBasic.services },
  { label: 'クライアント', url: routesBasic.clients },
  { label: '製品紹介 (英語)', url: externalRoutes.products },
  {
    label: 'オープンソース (英語)',
    url: externalRoutes.opensource,
  },
  { label: 'ブログ', url: routesBasic.blog },
  { label: 'イベント情報 (英語)', url: externalRoutes.events },
];
