// extracted by mini-css-extract-plugin
export var dateInterval = "EventCard-module--dateInterval--9ca09";
export var size_lg = "EventCard-module--size_lg--44683";
export var size_lg_condenced = "EventCard-module--size_lg_condenced--8c71d";
export var size_md = "EventCard-module--size_md--81f37";
export var size_sm = "EventCard-module--size_sm--64f8e";
export var text = "EventCard-module--text--4deea";
export var upcomingDate = "EventCard-module--upcomingDate--6a31b";
export var upcomingDateDay = "EventCard-module--upcomingDateDay--97d01";
export var upcomingDateDaySmall = "EventCard-module--upcomingDateDaySmall--f5c0a";
export var upcomingDateMonth = "EventCard-module--upcomingDateMonth--9bb9a";
export var upcomingDateMonthSmall = "EventCard-module--upcomingDateMonthSmall--3a1a1";
export var upcomingDateSmall = "EventCard-module--upcomingDateSmall--97137";
export var upcomingDateStartMonth = "EventCard-module--upcomingDateStartMonth--d53c8";
export var upcomingDateStartMonthMobile = "EventCard-module--upcomingDateStartMonthMobile--db3ea";
export var upcomingDateWrapper = "EventCard-module--upcomingDateWrapper--68515";
export var withImage = "EventCard-module--withImage--66c33";