import * as React from 'react';
import { GatsbyImage, type IGatsbyImageData } from 'gatsby-plugin-image';

import { useMedia, MOBILE_QUERY } from '../../../utils/useMedia';

interface Props {
  src?: string;
  width?: number;
  height?: number;
  mobileSrc?: string;
  mWidth?: number;
  mHeight?: number;
  image: IGatsbyImageData | null | undefined;
  mobileImage: IGatsbyImageData | null | undefined;
  alt?: string;
  className?: string;
}

export const useGetImage = ({
  src,
  width,
  height,
  mobileSrc,
  mWidth,
  mHeight,
  image,
  mobileImage,
  alt,
  className,
}: Props) => {
  const isMobile = useMedia(MOBILE_QUERY);
  const gatsbyMobileImage = mobileImage || image;

  if (isMobile) {
    if (mobileSrc || src) {
      return (
        <img
          className={className}
          alt={alt}
          src={mobileSrc || src}
          height={mHeight || height}
          width={mWidth || width}
          loading="lazy"
        />
      );
    } else if (gatsbyMobileImage) {
      return (
        <GatsbyImage
          className={className}
          alt={alt || ''}
          image={gatsbyMobileImage}
          objectFit="contain"
        />
      );
    }
  }

  if (src) {
    return (
      <img
        className={className}
        alt={alt}
        src={src}
        height={height}
        width={width}
        loading="lazy"
      />
    );
  } else if (image) {
    return (
      <GatsbyImage
        className={className}
        alt={alt || ''}
        image={image}
        objectFit="contain"
      />
    );
  }

  return null;
};
