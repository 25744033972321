type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export function track(eventName: string, label?: string) {
  if (
    typeof window === 'undefined' ||
    typeof window.dataLayer === 'undefined'
  ) {
    return;
  }

  window.dataLayer.push({
    event: 'customEventToGA',
    eventName: eventName,
    eventLabel: label,
  });
}
