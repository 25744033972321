import { routes } from '../config/routes';
import { routeWithParams } from './url';
import { onlyUniqueBy } from './array';

interface Options {
  categories?: readonly CustomTypes.FilterTag[];
  services?: readonly CustomTypes.FilterTag[];
  skills?: readonly CustomTypes.FilterTag[] | null;
  withEntityType?: boolean;
  slice?: boolean;
}

export function getBlogPostTags({
  categories = [],
  skills = [],
  services = [],
  withEntityType,
  slice = true,
}: Options): CustomTypes.Tags {
  const notInCategory = (item: CustomTypes.FilterTag) =>
    !categories.some(
      (category) =>
        item.shortTitle === category.title || item.title === category.title,
    );

  const notInService = (item: CustomTypes.FilterTag) =>
    !services.some(
      (service) =>
        item.shortTitle === service.shortTitle ||
        item.title === service.shortTitle ||
        item.shortTitle === service.title ||
        item.title === service.title,
    );

  const filteredServices = services.filter(notInCategory);

  const filteredSkills = (skills || [])
    .filter(notInCategory)
    .filter(notInService)
    .filter(onlyUniqueBy('slug'));

  const entityType = withEntityType
    ? [{ label: 'ブログ記事', type: 'EntityType', to: routes.blog }]
    : [];

  return [
    ...entityType,
    ...categories.map((category) => ({
      label: category.title,
      type: 'Category',
      to: routeWithParams(routes.blog, {
        categories: category.slug,
      }),
    })),
    ...filteredServices
      .map((service) => ({
        label: slice ? service.shortTitle || service.title : service.title,
        shortLabel: service.shortTitle,
        type: 'Service',
        to: routeWithParams(routes.blog, {
          services: service.slug,
        }),
      }))
      .slice(0, slice ? 2 : undefined),
    ...filteredSkills
      .map((skill) => ({
        label: slice ? skill.shortTitle || skill.title : skill.title,
        shortLabel: skill.shortTitle,
        type: 'Skill',
        to: routeWithParams(routes.blog, {
          skills: skill.slug,
        }),
      }))
      .slice(0, slice ? 3 : undefined),
  ];
}
