exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-422-tsx": () => import("./../../../src/pages/422.tsx" /* webpackChunkName: "component---src-pages-422-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-chronicles-index-tsx": () => import("./../../../src/pages/chronicles/index.tsx" /* webpackChunkName: "component---src-pages-chronicles-index-tsx" */),
  "component---src-pages-clients-index-tsx": () => import("./../../../src/pages/clients/index.tsx" /* webpackChunkName: "component---src-pages-clients-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-service-slug-tsx": () => import("./../../../src/pages/services/{Service.slug}.tsx" /* webpackChunkName: "component---src-pages-services-service-slug-tsx" */)
}

