import * as React from 'react';
import { getImage, type IGatsbyImageData } from 'gatsby-plugin-image';
import * as culori from 'culori';

import isDarkColor from '../../../utils/isDarkColor';
import { ThemeSentinel } from '../../ThemeSentinel/ThemeSentinel';
import { useWindowResizeEventListener } from '../../../utils/useWindowEventListener';
import { useMedia, MOBILE_QUERY } from '../../../utils/useMedia';
import { useGetImage } from './useGetImage';

import * as styles from './MarkdownWideSection.module.css';

interface Props {
  children: React.ReactNode;
  background?: string;
  secondary?: string;
  primary?: string;
  position?: string;
  alt?: string;
  gatsbyImageData?: IGatsbyImageData;
  mobileGatsbyImageData?: IGatsbyImageData;
  src?: string;
  width?: number;
  height?: number;
  mobileSrc?: string;
  mWidth?: number;
  mHeight?: number;
  customCss?: string;
}

function toScopedCss(css: string, id: string) {
  const sheet = new CSSStyleSheet();
  sheet.replaceSync(css);
  const rules: string[] = [];

  for (const rule of sheet.cssRules) {
    if (!(rule instanceof CSSStyleRule)) continue;

    const selectorValue = rule.selectorText;
    const selectors = selectorValue.split(',');

    rule.selectorText = selectors
      .map((selector: string) => {
        const sel = selector.trim();
        return `[data-section-id="${id}"] ${sel.replace(':root', '')}`;
      })
      .join(',');
    rules.push(rule.cssText);
  }

  return rules.join('\n');
}

let counter = 0;

function getSectionId() {
  return `sec-${counter++}`;
}

function setTransparency(color: string, amount: number): string {
  const culoriColor = culori.parse(color);
  const rgb = culori.rgb(culoriColor);
  if (!rgb) return color;
  const rgbaColor = culori.rgb({
    mode: 'rgb',
    r: rgb.r,
    g: rgb.g,
    b: rgb.b,
    alpha: amount / 100,
  });

  return culori.formatRgb(rgbaColor);
}

export const MarkdownWideSection = ({
  children,
  background,
  secondary,
  primary,
  alt = '',
  gatsbyImageData,
  mobileGatsbyImageData,
  src,
  height,
  width,
  mobileSrc,
  mHeight,
  mWidth,
  customCss,
}: Props) => {
  const [sectionId] = React.useState(() => getSectionId());
  const isMobile = useMedia(MOBILE_QUERY);

  const getScreenWidth = () =>
    typeof window !== 'undefined' ? window.innerWidth : 0;

  const [screenWidth, setScreenWidth] = React.useState(getScreenWidth);

  const handleResize = () => {
    setTimeout(() => {
      setScreenWidth(getScreenWidth());
    }, 0);
  };

  useWindowResizeEventListener(handleResize);

  const bgColorIsDark = isDarkColor(background || '#fff');
  const [userCss, setUserCss] = React.useState('');
  const image = gatsbyImageData ? getImage(gatsbyImageData) : null;
  const mobileImage = mobileGatsbyImageData
    ? getImage(mobileGatsbyImageData)
    : null;
  let widthRatio = 1;
  let imageHeight = 0;

  if (isMobile && mobileGatsbyImageData) {
    widthRatio = screenWidth / mobileGatsbyImageData.width;
    imageHeight = mobileGatsbyImageData.height * widthRatio;
  } else if (isMobile && mWidth && mHeight) {
    widthRatio = screenWidth / mWidth;
    imageHeight = mHeight * widthRatio;
  } else if (gatsbyImageData) {
    widthRatio = screenWidth / gatsbyImageData.width;
    imageHeight = gatsbyImageData.height * widthRatio;
  } else if (width && height) {
    widthRatio = screenWidth / width;
    imageHeight = height * widthRatio;
  }

  React.useEffect(() => {
    if (!customCss) return;
    setUserCss(toScopedCss(customCss, sectionId));
  }, [customCss, sectionId]);

  const imageElement = useGetImage({
    src,
    width,
    height,
    mobileSrc,
    mWidth,
    mHeight,
    image,
    mobileImage,
    alt,
    className: styles.image,
  });

  const style = React.useMemo(() => {
    const result = {};
    let secondaryColors = null;
    const primaryColor = primary && { '--color-theme-title': primary };
    const imageH = imageHeight && { '--image-height': `${imageHeight}px` };
    const bg = background && { '--color-theme-background': background };

    if (secondary) {
      secondaryColors = {
        '--color-theme-text': secondary,
        '--secondary-color-8': setTransparency(secondary, 8),
        '--secondary-color-20': setTransparency(secondary, 20),
      };
    }

    for (const piece of [primaryColor, secondaryColors, bg, imageH]) {
      if (piece) {
        Object.assign(result, piece);
      }
    }

    return result;
  }, [secondary, primary, background, imageHeight]);

  return (
    <>
      <section
        data-section-id={sectionId}
        className={styles.card}
        style={style}
      >
        {userCss && <style dangerouslySetInnerHTML={{ __html: userCss }} />}
        {bgColorIsDark && (
          <ThemeSentinel primaryColor={primary} secondaryColor={secondary} />
        )}
        {children}

        {imageElement}
      </section>
    </>
  );
};
