export const primaryFields = ['logo', 'title', 'navigation'];
export const secondaryFields = ['text', 'button'];

export const opacityRange = {
  dark: [0.1, 0.3],
  light: [0.05, 0.3],
};

const inversedTheme = {
  logo: '#fff',
  title: '#fff',
  navigation: '#fff',
  text: '#fff',
  button: '#fff',
  background: '#000',
};

export const errorTheme = inversedTheme;
export const mainTheme = inversedTheme;
export const withBgOverflowTheme = inversedTheme;

export const eventsTheme = {
  logo: '#fff',
  title: '#fff',
  navigation: '#fff',
  text: '#fff',
  button: '#fff',
  background: '#061431',
};
